import * as React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'
import Grid from '@material-ui/core/Grid'
import Layout from '../components/layout'
import Seo from '../components/seo'

const MOBILE_BREAKPOINT = 721
const isClient = typeof window !== 'undefined'

class EdgeUTrainingPage extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  }
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      viewportWidth: isClient ? window.innerWidth : 0,
    }
  }
  updateWindowDimensions = () => {
    this.setState({ viewportWidth: window.innerWidth })
  }
  componentDidMount() {
    if (isClient) {
      this.updateWindowDimensions()
      window.addEventListener('resize', this.updateWindowDimensions)
    }
  }

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const keywords = get(this, 'props.data.site.siteMetadata.keywords')
    const pageName = 'EdgeU Training'

    const { viewportWidth } = this.state
    const isMobile = Boolean(viewportWidth <= MOBILE_BREAKPOINT)
    var pagewidth = {
      matches: false,
      media: '(max-width: 600px)',
      onchange: null,
    }
    if (typeof window !== 'undefined') {
      pagewidth = window.matchMedia('(max-width: 600px)')
    }

    return (
      <React.Fragment>
        <Layout data={this.props} pagewidth={pagewidth.matches}>
          <Seo pagename={pageName + ' ' + siteTitle} keywords={keywords} />
          <div className="page_wrapper generalpage_wrapper">
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <h1 className="generalpage_majorheading">{pageName}</h1>
              </Grid>
              <Grid item xs={12}>
                Coming soon, we are focusing all our time on getting the app
                ready for full release, but are committed to posting this
                valuable information soon.
              </Grid>
            </Grid>
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}

export default withCookies(EdgeUTrainingPage)
export const EdgeUTrainingPageQuery = graphql`
  query EdgeUTrainingPageQuery {
    site {
      siteMetadata {
        title
        description
        menuLinks {
          name
          link
          cl
        }
        keywords
      }
    }
    sitePage {
      pluginCreator {
        name
      }
    }
  }
`
